.w_o_d_main {
  height: fit-content;
  grid-column: 5 / -1;
  border-radius: 8px;
  background: #fff;
  padding: 30px 30px 30px 25px;
}

.brown {
  color: #d57d2a;
}

// .diagnosisInfoArea{
//   border: 2px solid gray;
// }

.disabled {
  background-color: rgb(214, 168, 124) !important;
  cursor: none !important;
}

.gray {
  color: #00000066;
}

.usedReturnedComments {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  height: fit-content;
}

.usedReturnedCommentContent {
  height: 100%;
  overflow: auto;
}

.usedReturnedComments h5 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.usedReturnedComments span {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.57px;
  letter-spacing: -0.3333333432674408px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #71727a;
}

.black {
  color: black;
}

.gray2 {
  color: rgba(0, 0, 0, 0.5);
}

.white {
  color: #fff;
}

.darkBrown {
  color: #6c5b51;
}

.darkBrownBG {
  background-color: #6c5b51 !important;
}

.txtCapitalized {
  text-transform: capitalize !important;
}

.cursor {
  cursor: pointer;
}

.w_o_d_TopNav_inner {
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
}

.w_o_d_MainH1 {
  font-weight: 700;
  font-size: 13px;
  line-height: 16.32px;
  margin-bottom: 12px !important;
}

.margin12{
    margin-top: 12px !important;
}

.diagnosisCommentMainH1 {
  font-weight: 500;
  font-size: 17px;
  line-height: 16.32px;
}

.bold700 {
  font-weight: 700;
}

.w_o_d_TopHeader {
  margin: 20px 0;
}

.flex_space_btw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_space_btw2 {
  display: flex;
  align-items:normal;
  justify-content: space-between;
  width: 100%;
}

.w-o-aH3Container40 {
  width: 33%;
  position: relative;
  height: fit-content !important;
}

.w-o-aH3Container29 {
  width: 31%;
  position: relative;
}

.w-o-aH3Container60 {
  width: 67%;
}

.w-o-aPtag {
  line-height: 20px !important;
}

.w-o-aH3,
.w-o-aPtag {
  font-family: Mulish;
  font-size: 15px;
  line-height: 16.32px;
  font-weight: 500;
  letter-spacing: -0.3333333432674408px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: black;
}

.w-o-aH3 {
  margin-bottom: 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #00000061 !important;
}

.w_o_d_TopHeader_inner button {
  width: 140px;
  height: 38px;
  border-radius: 5px;
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

.w_o_d_MainH1NoMargin{
    margin-bottom: 0 !important;
}

.mainBorder{
    border: 1px solid #DFE0EB;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 28px 0;
}

.w_o_d_TopHeader_inner {
  margin-bottom: 40px;
}

.backgroundBrown {
  background-color: #d57d2a;
}

.d_i_i_TopHeader h1 {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.08px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.d_i_i_TopHeader span {
  margin-left: 5px;
}

.diagnosisTable {
  margin-top: 40px;
}

.borderBottom2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 7px;
}

.borderBottom1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 25px;
}

.borderBottom4 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//   padding-bottom: 8px;
}

.borderBottom3 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.diagnosisTableH1 {
  margin-bottom: 5px;
}

.d_t_c_Part span,
.d_t_c_Asset span,
.d_t_c_Solution span,
.d_t_c_Recommendation span {
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 21.57px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.diagnosisTableContent {
  position: relative;
  padding-top: 8px !important;
}

.d_t_c_Delete {
  position: absolute;
  right: 0;
  top: 50%;
  margin-left: 30px;
}

.d_t_c_left {
  width: 95%;
}

.d_t_c_Recommendation .d_t_c_spanBold,
.d_t_c_Part .d_t_c_spanBold,
.d_t_c_Asset .d_t_c_spanBold,
.d_t_c_Solution .d_t_c_spanBold,
.d_t_c_Part .d_t_c_spanBold {
  font-weight: 700;
}
.d_t_c_Recommendation,
.d_t_c_Part,
.d_t_c_Asset,
.d_t_c_Solution,
.d_t_c_Part {
  margin-bottom: 4px;
}

.d_t_c_spanBoldPart{
    font-size: 14px !important;
    font-weight: 400 !important;
}

.diagnosisInfoInner,
.d_p_i_Inner,
.d-w-i-inner {
  width: 95%;
  margin: auto;
}

.d-w-i-inner100 {
  width: 100% !important;
  margin: 0 !important;
}

.addDiagnosisBTN {
  margin: auto;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: right;
  width: 95%;
}

.addFilesInDiagnosisBTN {
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
}

.marginTop20 {
  margin-top: 20px;
}

.underline {
  text-decoration: underline;
}

.nav-item-d-p-i .active {
  border: none !important;
  color: #d57d2a !important;
}

.nav-item-d-p-i .nav-link:hover,
.nav-item-d-p-i .active:hover {
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
}

// .nav-item-d-p-i .nav-link {
//   color: black;
// }

// .nav-item-d-p-i button {
//   border: none !important;
//   outline: none !important;
//   padding-left: 0 !important;
//   font-size: 17px !important;
//   font-weight: 700 !important;
// }

.nav-tabs-d-p-i {
  border: none !important;
  margin-top: 8px !important;
  margin-bottom: 20px !important;
}

.flexCenter {
  font-weight: 600;
  margin-top: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.diagnosisPartsInfoArea {
  margin-top: 40px;
  height: fit-content;
}

// .d-p-i-td {
//   font-family: Mulish;
//   font-size: 15px;
//   font-weight: 400;
//   line-height: 18.83px;
//   letter-spacing: -0.3333333432674408px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   padding: 8px;
//   padding-left: 0;
//   padding-bottom: 15px;
// }

// .d-p-i-th {
//   font-family: Mulish;
//   font-size: 17px;
//   font-weight: 500;
//   line-height: 17.57px;
//   letter-spacing: -0.3333333432674408px;
//   text-align: left;
//   text-underline-position: from-font;
//   text-decoration-skip-ink: none;
//   padding: 8px;
//   padding-left: 0;
// }

.d-p-i-td-actions {
  padding-right: 0 !important;
  text-align: right;
}

.d-p-i-tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.d-p-i-thead {
  margin: 20px 0 !important;
}

.partsInfoBTN {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: right;
  margin-top: 40px;
}

.diagnosisWorkFilesInfo {
  margin-top: 40px !important;
}

.d-w-i-Content {
  margin: 8px 0;
}

.d-w-i-Content-left p {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 4.83px;
  letter-spacing: -0.3333333432674408px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 8px;
  padding-left: 0;
  padding-bottom: 15px;
  color: white;
  font-weight: 700;
}

.d-w-i-Content-leftP2{
    font-size: 13px !important;
    color: #71727A !important;
  }

.d-w-i-Content-left {
  position: relative !important;
  height: fit-content !important;
}

.customListItem {
    list-style: decimal;
  }

.imgDiagnosisPtag {
  position: absolute;
  left: 10%;
  bottom: 15%;
  font-size: 16px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
  display: inline-block;
  font-weight: bold !important;
  // filter: brightness(90%);
}

.diagnosisImgContainer {
  display: flex !important;
  align-items: center !important;
  gap: 20px;
  flex-wrap: wrap;
  margin: 35px auto;
}

// .diagnosisImgContainer2 {
//   display: flex !important;
//   align-items: center !important;
//   gap: 20px;
//   flex-wrap: wrap;
//   margin: 35px auto;
//   width: 100%;
// }
.diagnosisImgContainer {
  display: flex !important;
  align-items: center !important;
  gap: 20px;
  flex-wrap: wrap;
  margin: 35px auto;
}

.imgDiagnosis {
  width: 100%;
  height: 350px;
  // filter: brightness(80%);
  border-radius: 12px;
  border: 2px solid white;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -16px;
}

.imgDiagnosis2 {
  width: 30%;
}

.d-w-i-Content-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.diagnosisComments {
  margin: 50px 0 30px 0;
}

.approveRejectBtn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.w-o-a_MainH {
  margin-top: 40px;
  margin-bottom: 20px !important;
}

// .w-o-aH3Container{
//   line-height: 16px;
// }

@media (max-width: 991.98px) {
  .w_o_d_main {
    grid-column: 1 / -1;
  }
}
